// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import React, { createContext, useContext } from "react";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
  apiKey: "AIzaSyAMUbHkqQA9tekOwANv1AjUKlyaFxn7gyc",
  authDomain: "diana-website-7676c.firebaseapp.com",
  projectId: "diana-website-7676c",
  storageBucket: "diana-website-7676c.appspot.com",
  messagingSenderId: "169545690663",
  appId: "1:169545690663:web:22c24f3ccd125ad1b2e802",
  measurementId: "G-09J3R4KZVZ",
};

// Initialize Firebase
const app = initializeApp(config);
// const analytics = getAnalytics(app);

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
  const auth = getAuth(app);

  return (
    <FirebaseContext.Provider value={{ app, auth }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => useContext(FirebaseContext);
